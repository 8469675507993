import { useEffect, useState } from "react";

import InfoTooltip from "../../../../../components/modals/InfoTooltip/InfoTooltip";
import InputText from "../../../../../components/InputText/InputText";
import InputSelect from "../../../../../components/InputSelect/InputSelect";
import ButtonWithBorder from "../../../../../components/buttons/ButtonWithBorder/ButtonWithBorder";

import { regexEmail, regexNumericInput, regexStrokeInput } from "../../../../../utils/regex";
import employeeApi from "../../../../../utils/api/employeeApi";

function FormAddEmployee({ isOpen, closeForm, isLoading, onSubmit, addressId }) {

    const [name, setName] = useState('');
    const [nameValidText, setNameValidText] = useState('');
    const [email, setEmail] = useState('');
    const [emailValidText, setEmailValidText] = useState('');
    const [role, setRole] = useState('');
    const [roleValidText, setRoleValidText] = useState('');
    const [position, setPosition] = useState('');
    const [positionValidText, setPositionValidText] = useState('');
    const [phone, setPhone] = useState('');
    const [phoneValidText, setPhoneValidText] = useState('');
    const [wa, setWa] = useState('');
    const [waValidText, setWaValidText] = useState('');
    const [isValid, setIsValid] = useState(false);
    // форма
    const [isChekedEmail, setIsChekedEmail] = useState(false);
    const [currentEmployee, setCurrentEmployee] = useState(undefined);

    const optionRole = [
        {
            value: 'manager', name: 'председатель/управляющий'
        },
        {
            value: 'account', name: 'бухгалтер'
        },
    ]

    function submit() {

        const data = {
            name,
            email,
            position,
            role: role.value,
            address: addressId,
        }

        if (phone && phone !== '') {
            data.phoneNumber = phone.toString();
        }

        if (wa && wa !== '') {
            data.messengerNumber = wa.toString();
        }
        console.log(data)
        onSubmit(data, currentEmployee)
    }

    // найти сотрудник по email 
    async function checkEmail() {
        try {
            const employee = await employeeApi.getEmployeeByEmail(email);
            setCurrentEmployee(employee.employee);
            setIsChekedEmail(true);
        } catch (err) {
            console.log(err);
            if (err.message === 'Сотрудник с такой почтой не найден') {
                setIsChekedEmail(true);
            }
        }

    }

    // Если сотрудник уже забеден в БД - наполнить инпуты
    useEffect(() => {
        if (currentEmployee) {
            setName(currentEmployee.name);
            const currentRole = optionRole.find((item) => item.value === currentEmployee.role);
            setRole(currentRole);
        }
        if (currentEmployee?.contactData?.phoneNumber) {
            setPhone(currentEmployee.contactData.phoneNumber)
        }
        if (currentEmployee?.contactData?.messengerNumber) {
            setWa(currentEmployee.contactData.messengerNumber)
        }
    }, [currentEmployee])

    // Валидация
    useEffect(() => {

        const optionsValid = [
            { state: name, setValidText: setNameValidText, regex: regexStrokeInput, isValid: false },
            { state: email, setValidText: setEmailValidText, regex: regexEmail, isValid: false },
            { state: role.value, setValidText: setRoleValidText, regex: regexStrokeInput, isValid: false },
            { state: phone, setValidText: setPhoneValidText, regex: regexNumericInput, isValid: false },
            { state: wa, setValidText: setWaValidText, regex: regexNumericInput, isValid: false },
            { state: position, setValidText: setPositionValidText, regex: regexStrokeInput, isValid: false },
        ]

        optionsValid.forEach(option => {
            if (option.state !== '') {
                if (option.regex.test(option.state)) {
                    option.isValid = true;
                    option.setValidText('')
                } else {
                    option.isValid = false;
                    option.setValidText('Не корректный ввод')
                }
            } else {
                option.isValid = false;
                option.setValidText('Пропущено поле')
            }
        })

        const valid = optionsValid.find(option => option.isValid === false);
        setIsValid(!valid);

    }, [name, email, role])

    return (
        <InfoTooltip
            isOpen={isOpen}
            closeInfoTool={closeForm}
            text={'Добавить сотрудника'}
            children={
                <div className="employees__form-add-employee">
                    <>
                        <InputText inputValue={email} setInputChange={setEmail} placeholder={'Email'} validationText={emailValidText} label={'Email'} type={'email'} />
                        <div className="employees__form-add-employee-button">
                            <ButtonWithBorder text={'Проверить'} onClick={checkEmail} isLoading={isLoading} isDisabled={!regexEmail.test(email)} />
                        </div>
                    </>
                    {isChekedEmail ?
                        <>
                            <InputText inputValue={name} setInputChange={!currentEmployee ? setName : () => { }} placeholder={'ФИО'} validationText={nameValidText} label={'ФИО'} />
                            <InputText inputValue={position} setInputChange={!currentEmployee ? setPosition : () => { }} placeholder={'Должность'} validationText={positionValidText} label={'Должность'} />
                            <InputText inputValue={phone} setInputChange={!currentEmployee ? setPhone : () => { }} placeholder={'Телефон'} validationText={emailValidText} label={'Тел'} type={'number'} />
                            <InputText inputValue={wa} setInputChange={!currentEmployee ? setWa : () => { }} placeholder={'WhatsApp'} validationText={phoneValidText} label={'WA'} type={'number'} />
                            <InputSelect options={optionRole} setSelectValue={!currentEmployee ? setRole : () => { }} value={role} placeholder={'Роль'} validationText={waValidText} />
                            <div className="employees__form-add-employee-button">
                                <ButtonWithBorder text={'Добавить'} onClick={submit} isLoading={isLoading} isDisabled={!isValid} />
                            </div>
                        </>
                        : <></>}
                </div>
            }
            customStyle={''}
        />
    )
}

export default FormAddEmployee;