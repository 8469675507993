import Loader from "../../Loader/Loader";

function ButtonWithBorder({ text, onClick, isLoading, isDisabled }) {

    function click() {
        if (!isDisabled) {
            onClick();
        }
    }

    return (
        <div className={`button-with-border ${isDisabled ? 'button-with-border__disabled' : ''}`} onClick={click}>
            {!isLoading ?
                <p className="button-with-border__text">{text}</p>
                :
                <Loader customStyle={'loader__gray__w25h25'} />}
        </div>
    )
}

export default ButtonWithBorder;