function ItemList({ itemId, name, text, onClickRemoveItem }) {

    return (
        <div className="item-list" key={itemId}>
            <p className="item-list__text item-list__name">{name}</p>
            <div className="item-list__container">
                <p className="item-list__text">{text}</p>
                <p className="item-list__remove" onClick={onClickRemoveItem}>X</p>
            </div>
        </div>
    )

}

export default ItemList;