import { useEffect, useState } from "react";
import ButtonWithBackgroundColor from "../../../../components/buttons/ButtonWithBackgroundColor/ButtonWithBackgroundColor";
import ListEmployee from "./ListEmployee";
import addressDataApi from "../../../../utils/api/addressDataApi";
import FormAddEmployee from "./componens/FormAddEmployee";
import employeeApi from "../../../../utils/api/employeeApi";
import FormChoiceEditRemove from "../../../../components/forms/FormChoiceEditRemove/FormChoiceEditRemove";
import FormRemove from "../../../../components/forms/FormRemove/FormRemove";
import FormUpdateEmployee from "./componens/FormUpdateEmployee";


function Employees({ addressId }) {
    
    const [employees, setEmployees] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState(undefined);
    //формы
    const [isOpenFormAddEmployee, setOpenFormAddEmployee] = useState(false);
    const [isOpenFormChoiceEditRemove, setOpenFormChoiceEditRemove] = useState(false);
    const [isOpenFormRemove, setOpenFormRemove] = useState(false);
    const [isOpenFormUpdate, setOpenFormUpdate] = useState(false);
    //лоадеры
    const [isLoadingAddEmployee, setLoadingAddEmployee] = useState(false);
    const [isLoadingUpdate, setLoadingUpdate] = useState(false);
    const [isLoadingRemove, setLoadingRemove] = useState(false);

    function onClickItem(employee) {
        setOpenFormChoiceEditRemove(true);
        setSelectedEmployee(employee);
    }

    async function getEmpoyees() {
        try {
            const listEmployees = await addressDataApi.getEmployeesByAddressDataId(addressId);
            setEmployees(listEmployees.employees);
        } catch (err) {
            console.log(err);
        }
    }

    async function addEmployee(data) {
        try {
            setLoadingAddEmployee(true);
            //let employee = '';
            // Если сотрудника нет в БД - то создать
            // if (!currentEmployee) {
            //     const createdEmployee = await employeeApi.createEmployee(data);
            //     employee = createdEmployee.employee;
            // } else {
            //     employee = currentEmployee;
            // }
            // Добавить на адрес
            let employee = await employeeApi.createEmployee(data);
            //await employeeApi.addAddressDataToEmployee(employee._id, addressId);
            const updatedEmployes = [...employees, employee];
            setEmployees(updatedEmployes);
            setLoadingAddEmployee(false);
            setOpenFormAddEmployee(false);
        }
        catch (err) {
            console.log(err);
            setLoadingAddEmployee(false);
        }
    }

    function openFormUpdate() {
        setOpenFormChoiceEditRemove(false);
        setOpenFormUpdate(true);
    }

    function openFormRemove() {
        setOpenFormChoiceEditRemove(false);
        setOpenFormRemove(true);
    }

    async function update(data) {
        try {
            setLoadingUpdate(true);
            const employee = await employeeApi.updateEmployee(data);
            const updatedEmployess = employees.map((emp) => {
                if (emp._id.toString() === employee.employee._id.toString()) {
                    return employee.employee;
                } else {
                    return emp;
                }
            })
            setEmployees(updatedEmployess);
            setLoadingUpdate(false);
            setOpenFormUpdate(false)
        } catch (err) {
            setLoadingUpdate(false);
        }

    }

    async function remove() {
        try {
            setLoadingRemove(true);
            await employeeApi.removeAddressDataFromEmployee(selectedEmployee._id, addressId);
            const updatedEmployess = employees.filter((emp) => emp._id.toString() === selectedEmployee._id.toString());
            setEmployees(updatedEmployess);
            setLoadingRemove(false);
            setOpenFormRemove(false);
        }
        catch (err) {
            setLoadingRemove(false);
            console.log(err)
        }
    }

    // Наполнить массив сотрудников
    useEffect(() => {
        getEmpoyees();
    }, [])

    return (

        <div className="employees">
            <div className="employees-button-add">
                <ButtonWithBackgroundColor text={'Добавить сотрудника'} onClick={() => setOpenFormAddEmployee(true)} />
            </div>
            {employees.length > 0 ?
                <ListEmployee array={employees} onClickItem={onClickItem} />
                : <p>Нет записей</p>}
            <FormAddEmployee
                addressId={addressId}
                isOpen={isOpenFormAddEmployee}
                closeForm={() => setOpenFormAddEmployee(false)}
                isLoading={isLoadingAddEmployee}
                onSubmit={addEmployee}
            />
            <FormChoiceEditRemove
                isOpen={isOpenFormChoiceEditRemove}
                closeForm={() => { setOpenFormChoiceEditRemove(false) }}
                text={'Редактировать или удалить'}
                onEdit={openFormUpdate}
                onRemove={openFormRemove}
            />
            {isOpenFormRemove ?
                <FormRemove
                    isOpen={isOpenFormRemove}
                    closeForm={() => { setOpenFormRemove(false) }}
                    onRemove={remove}
                    isLoading={isLoadingRemove}
                />
                : <></>}
            <FormUpdateEmployee
                isOpen={isOpenFormUpdate}
                closeForm={() => { setOpenFormUpdate(false) }}
                onSubmit={update}
                isLoading={isLoadingUpdate}
                currentEmployee={selectedEmployee}
            />
        </div>

    )
}

export default Employees;