function getDaysDifference(startDate, endDate) {
    // Разница в миллисекундах между датами
    const timeDifference = endDate - startDate;
  
    // Рассчитываем количество дней (1 день = 24 часа * 60 минут * 60 секунд * 1000 миллисекунд)
    const daysDifference = Math.floor(timeDifference / (24 * 60 * 60 * 1000));
  
    return daysDifference;
  }

  /**
 * Форматирует дату в строке в локализованный русский формат.
 *
 * @param {string} inputDate - Строка с датой в формате 'год-месяц-день'.
 * @returns {string} - Локализованная строка с датой в формате 'день месяц год' на русском языке.
 *                      Возвращает 'Неверная дата', если входная строка не является корректной датой.
 */
function formatDate(inputDate) {
  const dateObject = new Date(inputDate);

  if (isNaN(dateObject.getTime())) {
      return 'Неверная дата';
  }

  const options = { day: 'numeric', month: 'numeric', year: 'numeric' };
  const formattedDate = new Intl.DateTimeFormat('ru-RU', options).format(dateObject);

  return formattedDate;
}

export { getDaysDifference, formatDate}