const optionRole = [
    {
        value: 'manager', name: 'председатель/управляющий'
    },
    {
        value: 'account', name: 'бухгалтер'
    },
]

export {
    optionRole,
}