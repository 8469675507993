import { optionRole } from "./utils/const";

function ListEmployee({ array, onClickItem }) {

    function onClick(item) {
        onClickItem(item);
    }

    return (
        <div className="employees__list">
            {array.map((item) => {
              
                let name = item.name;
                let email = '';
                let position = item.position;
                let phoneNumber = '';
                if (item.contactData.phoneNumber) {
                    phoneNumber = item.contactData.phoneNumber;
                }
                if (item.contactData.email) {
                    email = item.contactData.email;
                }
                let role = optionRole.find((el)=> el.value === item.role).name;
        
                return (
                    <div key={item._id} className="employees__item-list" onClick={() => onClick(item)}>
                        <div className="employees__item-list-container">
                            <p className="employees__item-list-text">{position }</p>
                            <p className="employees__item-list-text">{name}</p>
                        </div>
                        {name || phoneNumber ?
                            <div className="employees__item-list-container">
                                <p className="employees__item-list-text">телефон: {phoneNumber}</p>
                                <p className="employees__item-list-text">{email}</p>
                            </div>
                            : <></>}
                        <div className="employees__item-list-container">
                            <p className="employees__item-list-text">роль: {role}</p>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default ListEmployee;