import { useAppContext } from '../../context/AppContext';

function Footer({ }) {

    const { dictionary } = useAppContext();

    return (
        <footer className="footer">
            <p className='footer__text'>Управдом</p>
        </footer>
    )
}

export default Footer;