import { useNavigate } from "react-router-dom";
import { useState } from "react";

import FormAddRecord from "./components/FormAddRecord/FormAddRecord";
import InfoTooltip from "../../components/modals/InfoTooltip/InfoTooltip";
import ButtonWithBackgroundColor from "../../components/buttons/ButtonWithBackgroundColor/ButtonWithBackgroundColor";
import addressDataApi from "../../utils/api/addressDataApi";

function NewServicePage({ }) {

    const [isOpenedInfotool, setOpenedInfotool] = useState(false);
    const [textInfoTool, setTextInfoTool] = useState('');
    const [isLoadingSubmit, setLoadingSubmit] = useState(false);

    const navigate = useNavigate();

    function onClickBackPage() {
        navigate('/');
        setOpenedInfotool(false);
    }

    function openInfotool(text) {
        setOpenedInfotool(true);
        setTextInfoTool(text);
    }

    function add(data) {

        setLoadingSubmit(true);
        setTimeout(async () => {
            try {
                await addressDataApi.createAddressData(data);
                openInfotool('Успешо');
                setLoadingSubmit(false);
            } catch (error) {
                setLoadingSubmit(false);
                openInfotool(error.message);
            }
        }, 370)

    }

    return (
        <section className="new-address-page">
            <FormAddRecord handleSubmit={add} isLoading={isLoadingSubmit} />
            <InfoTooltip
                isOpen={isOpenedInfotool}
                closeInfoTool={() => setOpenedInfotool(false)}
                text={textInfoTool}
                children={
                    <div className="new-address-page__button-infotool">
                        <ButtonWithBackgroundColor
                            text={'На главную'}
                            onClick={onClickBackPage}
                        />
                    </div>
                }
            />
        </section>
    )
}

export default NewServicePage;