import host from "./host";

const addressDataApi = {
    host: host,

    createAddressData(data) {
       
        return fetch(`${this.host}/address-data`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message === "Validation failed" ? errorJson.validation.body.message : errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    },

    getAddressDataByIdForPay(id){
        return fetch(`${this.host}/address-data-by-idforpay/${id}`, {
            method: 'GET',
            credentials: 'include',
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message === "Validation failed" ? errorJson.validation.body.message : errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        });
    },

    updateAddressDataValidUntilById(id, validUntil){
        return fetch(`${this.host}/address-data`, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({id, validUntil})
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message === "Validation failed" ? errorJson.validation.body.message : errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        }); 
    },

    getEmployeesByAddressDataId(id){
        return fetch(`${this.host}/employees-by-addressid/${id}`, {
            method: 'GET',
            credentials: 'include',
        }).then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                return res.json().then((errorJson) => {
                    const error = new Error(errorJson.message === "Validation failed" ? errorJson.validation.body.message : errorJson.message);
                    error.json = errorJson;
                    error.status = res.status;
                    return Promise.reject(error);
                });
            }
        }); 
    }
}

export default addressDataApi;