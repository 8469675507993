import { useEffect, useState } from "react";
import { useAppContext } from '../../context/AppContext';
import InputText from "../InputText/InputText";
import ButtonWithBackgroundColor from "../buttons/ButtonWithBackgroundColor/ButtonWithBackgroundColor";
import { regexEmail, regexPassword } from "../../utils/regex";

function Login({ onLogin, isLoading }) {

    const { dictionary } = useAppContext();

    // Стейты, в которых содержятся значения инпута
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [validationMassegePassword, setValidationMassegePassword] = useState(dictionary.validationValue);
    const [validationMassegeLogin, setValidationMassegeLogin] = useState(dictionary.validationValue);
    const [validationAll, setValidationAll] = useState(false);

    function submit() {
        const data = {
            login: login,
            password: password,
        }
        onLogin(data);
    }

    // Валидация
    useEffect(() => {

        const validLogin = login !== '' && regexEmail.test(login) ? true : false;
        const validPassword = password !== '' && regexPassword.test(password) ? true : false;

        if (validLogin && validPassword) {
            setValidationAll(true);
            setValidationMassegeLogin('');
            setValidationMassegePassword('');
        } else {
            setValidationAll(false);
            !regexEmail.test(login)? setValidationMassegeLogin('Логин должен быть формата test@email.ru') : setValidationMassegeLogin('');
            !regexPassword.test(password) ? setValidationMassegePassword('Пароль содержит не допустимые символы') : setValidationMassegePassword('');
        }

    }, [login, password])


    return (
        <div className="login">
            <h2 className="login__title">{dictionary.titleLogin}</h2>
            <InputText
                inputValue={login}
                setInputChange={setLogin}
                placeholder={dictionary.placeholderLogin}
                validationText={validationMassegeLogin}
                label={dictionary.placeholderLogin}
            />
            <InputText
                inputValue={password}
                setInputChange={setPassword}
                placeholder={dictionary.placeholderPassword}
                validationText={validationMassegePassword}
                label={dictionary.placeholderPassword}
            />
            <div className="login__button">
                <ButtonWithBackgroundColor isLoading={isLoading} text={dictionary.buttonLogin} onClick={submit} isDisabled={!validationAll} />
            </div>
            <div className="login__container-text-password-recover">
                <p className="login__container-text-password-recover-text">{dictionary.textQuestionRecoveryPassword}</p>
                <button className="login__container-text-password-recover-link">{dictionary.textRecovery}</button>
            </div>
        </div>
    )
}

export default Login;