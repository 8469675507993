import React, { useEffect, useState } from "react";
import InputText from "../../../../../components/InputText/InputText";
import ButtonWithBackgroundColor from "../../../../../components/buttons/ButtonWithBackgroundColor/ButtonWithBackgroundColor";
import { regexNumericInput, regexEmail, regexStrokeInput } from "../../../../../utils/regex";

/**
 * Компонент формы для добавления настроек компании.
 * @param {Object} props - Свойства компонента.
 * @param {Object} props.handleSubmit - Функция обработки сабмита формы.
 * @param {Function} props.handleSubmit(formData) - Функция, которая будет вызвана при сабмите формы. Принимает объект formData с данными формы.
 * @returns {JSX.Element} - Элемент компонента формы добавления настроек компании.
 */
function FormAddSettingCompany({ handleSubmit }) {
    const [formData, setFormData] = useState({
        automaticSendingRecipients: false,
        yearlyBudget: '',
        accumulatedFund: '',
        useDefaultNorm: false,
        phoneNumber: '',
        messengerNumber: '',
        email: '',
        serviceSeparator: 'м2'
    });

    const [validationText, setValidationText] = useState({
        yearlyBudget: '',
        accumulatedFund: '',
        phoneNumber: '',
        messengerNumber: '',
        email: ''
    });

    const [isValidForm, setIsValidForm] = useState(false);
    const [messageValidForm, setMessageValidForm] = useState('');

    const handleChange = (value, fieldName) => {
        setFormData(prevState => ({
            ...prevState,
            [fieldName]: value
        }));
    };

    function submit() {
        if (isValidForm) {
            handleSubmit(formData);
        }
    }

    // Валидация
    useEffect(() => {
        const validations = {
            yearlyBudget: formData.yearlyBudget !== '' && regexNumericInput.test(formData.yearlyBudget),
            accumulatedFund: formData.accumulatedFund !== '' && regexNumericInput.test(formData.accumulatedFund),
            phoneNumber: formData.phoneNumber !== '' && regexNumericInput.test(formData.phoneNumber),
            messengerNumber: formData.messengerNumber !== '' && regexNumericInput.test(formData.messengerNumber),
            email: formData.email !== '' && regexEmail.test(formData.email)
        };

        const isValidForm = Object.values(validations).every(validation => validation);
        setIsValidForm(isValidForm);

        setValidationText({
            yearlyBudget: validations.yearlyBudget ? '' : 'Пожалуйста, введите корректный годовой бюджет',
            accumulatedFund: validations.accumulatedFund ? '' : 'Пожалуйста, введите корректный накопленный фонд',
            phoneNumber: validations.phoneNumber ? '' : 'Пожалуйста, введите корректный номер телефона',
            messengerNumber: validations.messengerNumber ? '' : 'Пожалуйста, введите корректный номер мессенджера',
            email: validations.email ? '' : 'Пожалуйста, введите корректный email'            
        });
    }, [formData]);

    return (
        <div className="form-add-record">
            <p className="form-add-record__title">Настройки компании</p>
            <div className="form-add-record__container-input">
                <InputText
                    inputValue={formData.yearlyBudget}
                    setInputChange={(e) => handleChange(e, 'yearlyBudget')}
                    placeholder={'Годовой бюджет'}
                    label={'Годовой бюджет'}
                    type={'number'}
                    validationText={validationText.yearlyBudget}
                />
                <InputText
                    inputValue={formData.accumulatedFund}
                    setInputChange={(e) => handleChange(e, 'accumulatedFund')}
                    placeholder={'Фонд'}
                    label={'Фонд'}
                    type={'number'}
                    validationText={validationText.accumulatedFund}
                />
                <InputText
                    inputValue={formData.phoneNumber}
                    setInputChange={(e) => handleChange(e, 'phoneNumber')}
                    placeholder={'Телефон для связи ( формат 79999999999)'}
                    label={'Телефон'}
                    type={'number'}
                    validationText={validationText.phoneNumber}
                />
                <InputText
                    inputValue={formData.messengerNumber}
                    setInputChange={(e) => handleChange(e, 'messengerNumber')}
                    placeholder={'Номер мессенджера'}
                    label={'формат 79999999999'}
                    type={'number'}
                    validationText={validationText.messengerNumber}
                />
                <InputText
                    inputValue={formData.email}
                    setInputChange={(e) => handleChange(e, 'email')}
                    placeholder={'Email'}
                    label={'Email'}
                    validationText={validationText.email}
                />
            </div>
            <ButtonWithBackgroundColor
                text={'Добавить'}
                isDisabled={!isValidForm}
                onClick={submit}
            />
            <p className="form-add-work__error-message">{messageValidForm}</p>
        </div>
    );
}

export default FormAddSettingCompany;
