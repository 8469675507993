import { useNavigate } from "react-router-dom";
import { useAppContext } from '../../context/AppContext';
import NavigatorButton from "./Components/NavigatorButton/NavigatorButton";

function MainPage() {

    const navigate = useNavigate();

    const {
        dictionary,
    } = useAppContext();

    function onClickAddNewService(e) {
        e.preventDefault();
        navigate('/add-service');
    }

    function onClickCustomerRecord(e) {
        e.preventDefault();
        navigate('/customer-record');
    }

    function onClickClients(e) {
        e.preventDefault();
        navigate('/clients');
    }

    function onClickMaintenances(e) {
        e.preventDefault();
        navigate('/maintenances');
    }

    function onClickAddresses(e) {
        e.preventDefault();
        navigate('/addresses');
    }

    return (
        <section className="main-page">
            <NavigatorButton text={'Новый объект адреса'} onClick={onClickAddNewService} />
            <NavigatorButton text={'Адреса'} onClick={onClickAddresses} />
            {/* <NavigatorButton text={dictionary.buttonsNavigatorClients} onClick={onClickClients} />
            <NavigatorButton text={dictionary.buttonsNavigatorCustomerRecord} onClick={onClickCustomerRecord} />
            <NavigatorButton text={dictionary.buttonsNavigatorMaintenances} onClick={onClickMaintenances} /> */}
        </section>
    )
}

export default MainPage;