import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from '../../context/AppContext';
import Login from "../../components/Login/Login";
import userApi from "../../utils/api/adminApi";
import InfoTooltip from "../../components//modals/InfoTooltip/InfoTooltip";

function LoginPage({ }) {

    const [isLoading, setIsLoading] = useState(false);
    const [isOpenInfoTooltip, setIsOpenInfoTooltip] = useState(false);
    const [textInfoTooltip, setTextInfotooltip] = useState('');

    const { idDevice, login } = useAppContext();
    const navigate = useNavigate();

    function redirect() {
        navigate('/');
    }

    function closeInfoTooltip() {
        setIsOpenInfoTooltip(false);
        setTextInfotooltip('');
    }

    function loginUser(data) {
        setIsLoading(true);
        userApi.login(data, idDevice)
            .then(data => {
                data.authState = true;
                login(data);
                redirect();
                setIsLoading(false);
            })
            .catch(err => {
                if (err.message.includes('Повторная')) {
                    setTextInfotooltip('Превышен лимит попыток, повторите позже');
                } else {
                    setTextInfotooltip('Проверьте данные для авторизации');
                }
                setIsOpenInfoTooltip(true);
                data.authState = false;
                console.log(err);
                setIsLoading(false);
            })
    }

    return (
        <>
            <Login onLogin={loginUser} isLoading={isLoading} />
            <InfoTooltip isOpen={isOpenInfoTooltip} closeInfoTool={closeInfoTooltip} text={textInfoTooltip} />
        </>
    )
}

export default LoginPage;