const communalServicesForSelector = [
    { value: 'холодная вода', name: 'холодная вода' },
    { value: 'горячая вода', name: 'горячая вода' },
    { value: 'отопление', name: 'отопление' },
    { value: 'водоотведение', name: 'водоотведение' },
    { value: 'газ', name: 'газ' },
    { value: 'электричество 1', name: 'электричество 1' },
    { value: 'электричество 2', name: 'электричество 2' },
    { value: 'электричество 3', name: 'электричество 3' },
]

const additionalServicesForSelector = [
    { value: 'вывоз мусора', name: 'вывоз мусора' },
    { value: 'членский взнос', name: 'членский взнос' },
    { value: 'целевой взнос', name: 'целевой взнос' },
    { value: 'ежегодный взнос', name: 'ежегодный взнос' },
    { value: 'ежемесячный взнос', name: 'ежемесячный взнос' },
    { value: 'охрана', name: 'охрана' },
]

const additionalServicesMeseurementForSelector = [
    { value: 'раз', name: 'раз' },
]

const communalServicesMeseurementForSelector = [
    { value: 'м3', name: 'м3' },
    { value: 'Гкал', name: 'Гкал' },
    { value: 'кВт/ч', name: 'кВт/ч' },
]

export {
    communalServicesForSelector,
    communalServicesMeseurementForSelector,
    additionalServicesForSelector,
    additionalServicesMeseurementForSelector
}