import React from 'react';
import Select from 'react-select';

/**
 * Компонент выпадающего списка.
 * @param {Object} props - Свойства компонента.
 * @param {Object[]} props.options - Массив объектов, представляющих варианты выбора в списке.
 * @param {string} props.options[].value - Значение для выбора.
 * @param {string} props.options[].name - Отображаемое имя в списке.
 * @param {function} props.setSelectValue - Функция обратного вызова, вызываемая при выборе значения из списка.
 * @param {Object} props.value - Текущее выбранное значение.
 * @param {string} props.placeholder - Текст-плейсхолдер для поля ввода.
 * @param {string} props.validationText - Текст сообщения об ошибке валидации.
 * @param {function} props.handleInputChange - Функция обработки изменения ввода.
 * @returns {JSX.Element} - Элемент выпадающего списка.
 */
function InputSelect({ options, setSelectValue, value, placeholder, validationText, handleInputChange }) {

    const handleChange = (selectedOption) => {
        if (setSelectValue) {
            setSelectValue(selectedOption);
        }
    };

    const customStyles = {
        control: (provided) => ({
            ...provided,
            fontSize: '16px', // Пример задания размера текста
            fontFamily: '"Inter",Arial, sans-serif', // Пример задания шрифта
            marginTop: '10px',
            border: 'none', // Пример пользовательских стилей
            borderBottom: '1px solid rgba(0, 0, 0, 0.2)', // Устанавливаем нижнюю грань рамки с желаемым цветом
            borderRadius: 0,
            padding: 0,
        })
    };

    return (
        <>
            <Select
                options={options.map(option => ({ value: option.value, label: option.name }))}
                onChange={handleChange}
                onInputChange={handleInputChange}
                value={value}
                placeholder={placeholder}
                styles={customStyles} // Передача пользовательских стилей
                className='input-select'
            />
            <span className="input__text-error" id="error-firstname">{validationText}</span>
        </>
    );
}

export default InputSelect;
