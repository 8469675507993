import React, { useEffect, useState } from "react";
import InputText from "../../../../../components/InputText/InputText";
import ButtonWithBackgroundColor from "../../../../../components/buttons/ButtonWithBackgroundColor/ButtonWithBackgroundColor";
import List from "../../../../../components/list/List/List";
import InputSelect from "../../../../../components/InputSelect/InputSelect";
import { communalServicesForSelector, communalServicesMeseurementForSelector } from "../../../../../utils/const";
import { regexStrokeInput } from "../../../../../utils/regex";

/**
 * Компонент формы для добавления коммунальных услуг.
 * @param {Object} props - Свойства компонента.
 * @param {Function} props.handleSubmit - Функция обработки сабмита формы.
 * @returns {JSX.Element} - Элемент компонента формы добавления коммунальных услуг.
 */
function FormAddCommonService({ handleSubmit }) {
    const [formData, setFormData] = useState([]); // Массив данных о добавленных услугах
    const [services, setServices] = useState([]); // Массив для отображения добавленных услуг
    const [serviceData, setServiceData] = useState({ // Данные новой услуги
        name: "",
        cost: "",
        measurementUnit: "",
        defaultNorm: "",
    });

    const [isValidServiceForm, setIsValidServiceForm] = useState(false); // Флаг валидности формы добавления услуги
    const [isValidForm, setIsValidForm] = useState(false); // Флаг валидности всей формы

    /**
     * Обработчик изменения значений полей формы.
     * @param {string} value - Новое значение поля.
     * @param {string} fieldName - Наименование поля.
     */
    const handleChange = (value, fieldName) => {
        setServiceData((prevState) => ({
            ...prevState,
            [fieldName]: value,
        }));
    };

    /**
     * Функция добавления новой услуги.
     */
    function addService() {
        setFormData((prevData) => [...prevData, serviceData]); // Добавление данных новой услуги в массив
        // Очистка формы после добавления услуги
        setServiceData({
            name: "",
            cost: "",
            measurementUnit: "",
            defaultNorm: "",
            periodicity: "monthly"
        });
        // Добавление услуги в список для отображения
        const service = {
            name: serviceData.name,
            text: `Стоимость: ${serviceData.cost}, Ед измерения: ${serviceData.measurementUnit}, Норма за 1: ${serviceData.defaultNorm}`
        }
        setServices((prevData) => [...prevData, service]);
    }

    function removeItem(itemRemoved) {
        const newArray = formData.filter(item => item.name !== itemRemoved.name);
        setFormData(newArray);
        const newList = services.filter(item => item.name !== itemRemoved.name);
        setServices(newList);
    }

    /**
     * Функция сабмита формы.
     */
    function submit() {
        const data = { commonServices: formData } // Формирование данных для отправки
        handleSubmit(data); // Обработка сабмита
        // Очистка массива услуг после сабмита формы
        setFormData();
        setServices([]);
    }

    useEffect(() => {
        // Валидация данных новой услуги
        const validations = {
            name: serviceData.name !== '' && regexStrokeInput.test(serviceData.name),
            cost: serviceData.cost !== '' && !isNaN(serviceData.cost),
            measurementUnit: serviceData.measurementUnit !== '',
            defaultNorm: serviceData.defaultNorm !== '' && !isNaN(serviceData.defaultNorm),
            periodicity: serviceData.periodicity !== '' && regexStrokeInput.test(serviceData.periodicity)
        };
        const isValidForm = Object.values(validations).every(validation => validation); // Проверка валидности формы
        setIsValidServiceForm(isValidForm); // Установка флага валидности формы
    }, [serviceData]);

    useEffect(() => {
        if (services) {
            const isValid = services.length > 0; // Проверка наличия добавленных услуг
            setIsValidForm(isValid); // Установка флага валидности всей формы
        }
    }, [services]);

    return (
        <div className="form-add-record">
            <p className="form-add-record__title">Добавление общих коммунальных услуг</p>
            <div className="form-add-record__container-input">
                <InputSelect
                    options={communalServicesForSelector}
                    placeholder={'Выберите ком услугу'}
                    setSelectValue={(e) => handleChange(e.value, "name")}
                    validationText={!serviceData.name ? "Введите название услуги" : ""}
                />
                <InputSelect
                    options={communalServicesMeseurementForSelector}
                    placeholder={"Единица измерения"}
                    setSelectValue={(e) => handleChange(e.value, "measurementUnit")}
                    validationText={!serviceData.measurementUnit ? "Введите единицу измерения" : ""}
                />
                <InputText
                    inputValue={serviceData.cost}
                    setInputChange={(e) => handleChange(e, "cost")}
                    placeholder={"Стоимость"}
                    label={"Стоимость"}
                    type={"number"}
                    validationText={!serviceData.cost ? "Введите стоимость услуги" : isNaN(serviceData.cost) ? "Введите корректное значение стоимости" : ""}
                />
                <InputText
                    inputValue={serviceData.defaultNorm}
                    setInputChange={(e) => handleChange(e, "defaultNorm")}
                    placeholder={"Норма за 1 по умолчанию"}
                    label={"Норма по умолчанию"}
                    type={"number"}
                    validationText={!serviceData.defaultNorm ? "Введите норму по умолчанию" : isNaN(serviceData.defaultNorm) ? "Введите корректное значение нормы" : ""}
                />
                <InputSelect
                    options={[{ value: 'monthly', name: 'ежемесячно' }, { value: 'yearly', name: 'ежегодно' }]}
                    placeholder={"Периодичность"}
                    setSelectValue={(e) => handleChange(e.value, "periodicity")}
                    validationText={!serviceData.periodicity ? "Введите периодичность" : ""}
                />
            </div>
            <div className="form-add-record__button-container">
                <ButtonWithBackgroundColor
                    text={"Добавить услугу"}
                    onClick={addService}
                    isDisabled={!isValidServiceForm}
                />
            </div>
            <div className="form-add-record__list">
                <List array={services} onClickRemoveItem={removeItem} />
            </div>
            <div className="form-add-record__button-container">
                <ButtonWithBackgroundColor
                    text={"Сохранить услуги"}
                    onClick={submit}
                    isDisabled={!isValidForm}
                />
            </div>
        </div>
    );
}

export default FormAddCommonService;
