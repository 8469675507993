import host from "./host";

class Api {
    constructor(data) {
        this.host = data.host;
    }

    // проверка статуса запроса
    _getResponse(res) {
        if (res.ok) {
            return res.json();
        } else {
            return res.json().then((errorJson) => {
                const error = new Error(errorJson.message);
                error.json = errorJson;
                error.status = res.status;
                return Promise.reject(error);
            });
        }
    }
    //ДАННЫЕ **
    //пробросить данные для регистрации через АПИ
    register(data) {
        return fetch(`${this.host}/signup`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({ name: data.name, email: data.email, password: data.password, })
        }).then((res) => res.json())
            .catch((err) => console.log(err))
    };

    //пробросить данные из инпутов и отправить на сервер для авторизации пользователя
    login(dataUser) {
        return fetch(`${this.host}/signin-control`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                password: dataUser.password,
                email: dataUser.login,
            })
        }).then((res) => this._getResponse(res))
    };

    logout(idDevice) {
        return fetch(`${this.host}/logout-by-admin/${idDevice}`,
            {
                method: 'POST',
                credentials: 'include',
            },
        ).then((res) => this._getResponse(res))
    }

    //запрос на сервер для авторизации
    getToken(tkn) {
        return fetch(`${this.host}/users/me`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${tkn}`,
            },
            credentials: 'include',
        }).then((res) => res.json())
            .catch((err) => console.log(err))
    }

    // запрос данных пользователя
    getUserInfo() {
        return fetch(`${this.host}/control-get-self-user`,
            {
                method: 'GET',
                credentials: 'include'
            }
        ).then((res) => this._getResponse(res))
    }

    checkLogin(login, idDevice) {
        return fetch(`${this.host}/check-vacation-login/${login.toLowerCase()}/${idDevice}`,
            {
                method: 'GET',
                credentials: 'include',
            },
        ).then((res) => this._getResponse(res))
    }

    getUserTransportsByLoginUser(login, idDevice) {
        return fetch(`${this.host}/get-transports-user-by-login-user/${login.toLowerCase()}/${idDevice}`,
            {
                method: 'GET',
                credentials: 'include',
            },
        ).then((res) => this._getResponse(res))
    }

}

const userApi = new Api({
    host: host,
});

export default userApi;