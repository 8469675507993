import ButtonWithBorder from "../../../components/buttons/ButtonWithBorder/ButtonWithBorder";
import { formatDate } from "../../../utils/functions";


function AddressBalance({ address, handleOpenUpdateDate }) {
    return (
            <div className="addresses__container-balance-controle">
                <p className="addresses__container-balance-controle-text">Подписка до {formatDate(address.validUntil)}</p>
                <div className="addresses__container-balance-controle-button">
                    <ButtonWithBorder text={'Продлить подписку'} onClick={handleOpenUpdateDate} />
                </div>
            </div>
    )
}

export default AddressBalance;