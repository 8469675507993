import ItemList from "../ItemList/ItemList";

/**
 * Рендерит компонент списка на основе предоставленного массива элементов.
 * @param {Object} props - Свойства компонента.
 * @param {Array} props.array - Массив элементов для отображения в списке.
 * @param {Function} props.onClickRemoveItem - Функция для удаления элемента из списка.
 * @returns {JSX.Element} - Элемент компонента списка.
 */

function List({ array, onClickRemoveItem }) {
  
    return (
        array && array.length > 0 ?
            < div className="list" >
                {
                    array.map((item) => {
                        return (
                            <div key={item.name}>
                                <ItemList
                                    itemId={item.text}
                                    name={item.name}
                                    text={item.text}
                                    onClickRemoveItem={() => onClickRemoveItem(item)}
                                />
                            </div>
                        )
                    })
                }
            </div >
            :
            <></>
    )

}

export default List;