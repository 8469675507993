import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import FormAddAddress from "./components/FormAddAddress";
import FormAddCompany from "./components/FormAddCompany";
import FormAddSettingCompany from "./components/FormAddSettingCompany";
import FormAddContactsCompany from "./components/FormAddContactsCompany";
import FormAddCommunalServices from "./components/FormAddCommunalService";
import FormAddAdditionalService from "./components/FormAddAdditionalService";
import LoaderPage from "../../../../components/LoaderPage/LoaderPage";
import FormAddCommonService from "./components/FormAddCommonService";
import FormChoiseDate from "./components/FormChoiseDate";
import ButtonNavigationBack from "../../../../components/buttons/ButtonNavigationBack/ButtonNavigationBack";

function FormAddRecord({ handleSubmit }) {

    const [dataForm, setDataForm] = useState({});
    //Формы
    const [formsState, setFormsState] = useState([
        { component: FormAddAddress, componentName: 'FormAddAddress', isOpen: true },
        { component: FormAddCompany, componentName: 'FormAddCompany', isOpen: false },
        { component: FormAddSettingCompany, componentName: 'FormAddSettingCompany', isOpen: false },
        { component: FormAddContactsCompany, componentName: 'FormAddContactsCompany', isOpen: false },
        { component: FormAddCommunalServices, componentName: 'FormAddCommunalServices', isOpen: false },
        { component: FormAddCommonService, componentName: 'FormAddCommonService', isOpen: false },
        { component: FormAddAdditionalService, componentName: 'FormAddAdditionalService', isOpen: false },
        { component: FormChoiseDate, componentName: 'FormChoiseDate', isOpen: false }
    ]);

    async function addData(data) {
        const updatedDataForm = { ...dataForm, ...data };
        setDataForm(updatedDataForm);
    }

    async function handleSubmitForm(data) {
        const chekFormFull = formsState.find(form => form.componentName === 'FormChoiseDate')?.isOpen
        await addData(data);

        if (chekFormFull) {
            const updatedDataForm = { ...dataForm, ...data };
            handleSubmit(updatedDataForm);
        };

        const forms = [...formsState];
        const currentIndex = forms.findIndex(form => form.isOpen);
        const prevIndex = currentIndex + 1;
        if (forms[currentIndex].componentName !== 'FormChoiseDate') {
            // Закрываем текущую форму и открываем слудующую
            forms[currentIndex].isOpen = false;
            forms[prevIndex].isOpen = true;
            setFormsState(forms);
        }
    }

    const navigate = useNavigate();

    function onClickBackPage() {
        const forms = [...formsState];
        const currentIndex = forms.findIndex(form => form.isOpen);
        const prevIndex = currentIndex - 1;

        // Если текущая форма - FormAddAddress, переходим на главную страницу
        if (forms[currentIndex].componentName === 'FormAddAddress') {
            navigate('/');
            return;
        }

        // Закрываем текущую форму и открываем предыдущую
        forms[currentIndex].isOpen = false;
        forms[prevIndex].isOpen = true;
        setFormsState(forms);
    }

    return (
        <div className="form-add-record">
            <ButtonNavigationBack onClick={onClickBackPage} />
            {formsState.map((form, index) => {
                const FormComponent = form.component;
                return (
                    form.isOpen && <FormComponent
                        key={index}
                        handleSubmit={handleSubmitForm}
                    />
                );
            })}
        </div>
    );
}

export default FormAddRecord;
