import React, { useEffect, useState } from "react";
import InputText from "../../../../../components/InputText/InputText";
import ButtonWithBackgroundColor from "../../../../../components/buttons/ButtonWithBackgroundColor/ButtonWithBackgroundColor";
import { regexStrokeInput, regexEmail, regexNumericInput } from "../../../../../utils/regex";
import InputSelect from "../../../../../components/InputSelect/InputSelect";

/**
 * Компонент формы для добавления адреса.
 * @param {Object} props - Свойства компонента.
 * @param {Object} props.handleSubmit - Функция обработки сабмита формы.
 * @param {Function} props.handleSubmit(formData) - Функция, которая будет вызвана при сабмите формы. Принимает объект formData с данными формы.
 * @returns {JSX.Element} - Элемент компонента формы добавления адреса.
 */
function FormAddAddress({handleSubmit}) {
    const [formData, setFormData] = useState({
        city: '',
        street: '',
        building: '',
        numberOfUnits: '',
        commonArea: '',
        levels: '',
        typePropertyes: ''
    });

    const [cityValidationText, setCityValidationText] = useState('');
    const [streetValidationText, setStreetValidationText] = useState('');
    const [buildingValidationText, setBuildingValidationText] = useState('');
    const [numberOfUnitsValidationText, setNumberOfUnitsValidationText] = useState('');
    const [commonAreaValidationText, setCommonAreaValidationText] = useState('');
    const [levelsValidationText, setLevelsValidationText] = useState('');
    const [typePropertyesValidationText, setTypePropertyesValidationText] = useState('');

    const [isValidForm, setIsValidForm] = useState(false);
    const [messageValidForm, setMessageValidForm] = useState('');

    const handleChange = (value, fieldName) => {
        setFormData(prevState => ({
            ...prevState,
            [fieldName]: value
        }));
    };

    function submit(){
        if(isValidForm){
            handleSubmit(formData)
        }
    }

    //Валидация
    useEffect(() => {
        setCityValidationText('');
        setStreetValidationText('');
        setBuildingValidationText('');
        setNumberOfUnitsValidationText('');
        setCommonAreaValidationText('');
        setLevelsValidationText('');
        setTypePropertyesValidationText('');

        const validations = {
            city: formData.city !== '' && regexStrokeInput.test(formData.city),
            street: formData.street !== '' && regexStrokeInput.test(formData.street),
            building: formData.building !== '' && regexNumericInput.test(formData.building),
            numberOfUnits: formData.numberOfUnits !== '' && regexNumericInput.test(formData.numberOfUnits),
            commonArea: formData.commonArea !== '' && regexNumericInput.test(formData.commonArea),
            levels: formData.levels !== '' && regexNumericInput.test(formData.levels),
            typePropertyes: formData.typePropertyes !== '' && ['house', 'apartment'].includes(formData.typePropertyes)
        };

        setIsValidForm(Object.values(validations).every(validation => validation));

        if (!validations.city) setCityValidationText('Строка пустая или содержит неверные символы');
        if (!validations.street) setStreetValidationText('Строка пустая или содержит неверные символы');
        if (!validations.building) setBuildingValidationText('Строка пустая или содержит неверные символы');
        if (!validations.numberOfUnits) setNumberOfUnitsValidationText('Строка пустая или содержит неверные символы');
        if (!validations.commonArea) setCommonAreaValidationText('Строка пустая или содержит неверные символы');
        if (!validations.levels) setLevelsValidationText('Строка пустая или содержит неверные символы');
        if (!validations.typePropertyes) setTypePropertyesValidationText('Строка пустая или содержит неверные символы');

        if (!isValidForm) {
            setMessageValidForm('Пожалуйста, проверьте введенные данные.');
        } else {
            setMessageValidForm('');
        }

    }, [formData])

    return (
        <div className="form-add-record">
            <p className="form-add-record__title">Добавление адреса</p>
            <div className="form-add-record__container-input">
                <InputText
                    inputValue={formData.city}
                    setInputChange={(e) => handleChange(e, 'city')}
                    placeholder={'Город'}
                    label={'Город'}
                    validationText={cityValidationText}
                />
                <InputText
                    inputValue={formData.street}
                    setInputChange={(e) => handleChange(e, 'street')}
                    placeholder={'Улица'}
                    label={'Улица'}
                    validationText={streetValidationText}
                />
                <InputText
                    inputValue={formData.building}
                    setInputChange={(e) => handleChange(e, 'building')}
                    placeholder={'Дом'}
                    label={'Дом'}
                    type={'number'}
                    validationText={buildingValidationText}
                />
                <InputText
                    inputValue={formData.numberOfUnits}
                    setInputChange={(e) => handleChange(e, 'numberOfUnits')}
                    placeholder={'Количество объектов недвижимости'}
                    label={'Объекты'}
                    type={'number'}
                    validationText={numberOfUnitsValidationText}
                />
                <InputText
                    inputValue={formData.commonArea}
                    setInputChange={(e) => handleChange(e, 'commonArea')}
                    placeholder={'Общая площадь'}
                    label={'Общая площадь'}
                    type={'number'}
                    validationText={commonAreaValidationText}
                />
                <InputText
                    inputValue={formData.levels}
                    setInputChange={(e) => handleChange(e, 'levels')}
                    placeholder={'Количество этажей'}
                    label={'Этажи'}
                    type={'number'}
                    validationText={levelsValidationText}
                />
                <InputSelect
                    options={[
                        { value: 'house', name: 'дома' },
                        { value: 'apartment', name: 'квартиры' }]}
                    setSelectValue={(e) => handleChange(e.value, 'typePropertyes')}
                    placeholder={'Тип недвижимости'}
                    validationText={typePropertyesValidationText}
                />
            </div>
            <ButtonWithBackgroundColor
                text={'Далее'}
                isDisabled={!isValidForm}
                onClick={submit}
            />
            <p className="form-add-work__error-message">{messageValidForm}</p>
        </div>
    );
}

export default FormAddAddress;