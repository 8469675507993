/**
 * Компонент для ввода текста с меткой и сообщением об ошибке.
 * @param {Object} props - Свойства компонента.
 * @param {string} props.inputValue - Текущее значение ввода.
 * @param {Function} props.setInputChange - Функция обратного вызова для установки нового значения ввода.
 * @param {string} props.placeholder - Плейсхолдер для поля ввода.
 * @param {string} props.validationText - Текст сообщения об ошибке.
 * @param {string} props.label - Метка поля ввода.
 * @param {string} [props.type="text"] - Тип поля ввода (text, password, number и т. д.).
 * @returns {JSX.Element} - Элемент компонента InputText.
 */
function InputText({ inputValue, setInputChange, placeholder, validationText, label, type }) {

    function handleInputChange(e) {
        setInputChange(e.target.value);
    }

    return (
        <div className="input-container">
            <div className="input-with-label-container">
                {label && inputValue.length > 0 ?
                    <p className="input__label">{label}:</p>
                    : <></>}
                <input
                    type={type ? type : "text"}
                    placeholder={placeholder}
                    value={inputValue}
                    onChange={handleInputChange}
                    className={`input ${validationText && validationText.length > 0 ? "input_error" : ""}`}
                    id="username-input" minLength="2"
                    required
                />
            </div>
            <span className="input__text-error" id="error-firstname">{validationText}</span>
        </div>
    )
}

export default InputText;