import InfoTooltip from "../../../../components/modals/InfoTooltip/InfoTooltip";
import InputDateTimePicker from "../../../../components/InputDateTimePicker/InputDateTimePicker";
import ButtonWithBackgroundColor from "../../../../components/buttons/ButtonWithBackgroundColor/ButtonWithBackgroundColor";
import { useState } from "react";

/** Форма продления подписки */
function FormUpdateDate({ isOpen, close, handleSubmit, isLoading }) {

    const [date, setDate] = useState(new Date());

    function submit() {
        handleSubmit(date)
    }

    return (
        <InfoTooltip
            isOpen={isOpen}
            closeInfoTool={close}
            text={'Обновить дату'}
            children={
                <div className="form-add-record__container-input">
                    <InputDateTimePicker
                        handleSetDateTime={setDate}
                        validationText={'Выберите дату'}
                    />
                    <ButtonWithBackgroundColor
                        text={'Готово'}
                        onClick={submit}
                        isLoading={isLoading}
                    />
                </div>
            }
        />
    )
}

export default FormUpdateDate;