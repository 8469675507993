import React, { useEffect, useState } from "react";
import InputText from "../../../../../components/InputText/InputText";
import ButtonWithBackgroundColor from "../../../../../components/buttons/ButtonWithBackgroundColor/ButtonWithBackgroundColor";
import { regexNumericInput, regexEmail, regexStrokeInput } from "../../../../../utils/regex";

/**
 * Компонент формы для добавления компании.
 * @param {Object} props - Свойства компонента.
 * @param {Object} props.handleSubmit - Функция обработки сабмита формы.
 * @param {Function} props.handleSubmit(formData) - Функция, которая будет вызвана при сабмите формы. Принимает объект formData с данными формы.
 * @returns {JSX.Element} - Элемент компонента формы добавления компании.
 */
function FormAddCompany({handleSubmit}) {
    const [formData, setFormData] = useState({
        nameCompany: '',
        registrationNumber: '',
        taxId: '',
        mainPersonName: '',
        mainPersonPosition: '',
        bankName: '',
        BIC: '',
        accountNumber: '',
        routingNumber: '',
        emailCompany: '',
        phoneNumberCompany: '',
        postalAddressCompany: '',
        legalAddressCompany: ''
    });

    const [validationText, setValidationText] = useState({
        nameCompany: '',
        registrationNumber: '',
        taxId: '',
        emailCompany: '',
        phoneNumberCompany: ''
    });

    const [isValidForm, setIsValidForm] = useState(false);
    const [messageValidForm, setMessageValidForm] = useState('');

    const handleChange = (value, fieldName) => {
        setFormData(prevState => ({
            ...prevState,
            [fieldName]: value
        }));
    };

    function submit(){
        if(isValidForm){
            handleSubmit(formData)
        }
    }

    //Валидация
    useEffect(() => {
        const validations = {
            nameCompany: formData.nameCompany !== '' && regexStrokeInput.test(formData.nameCompany),
            registrationNumber: formData.registrationNumber !== '' && regexNumericInput.test(formData.registrationNumber),
            taxId: formData.taxId !== '' && regexNumericInput.test(formData.taxId),
            mainPersonName: formData.mainPersonName !== '' && regexStrokeInput.test(formData.mainPersonName),
            mainPersonPosition: formData.mainPersonPosition !== '' && regexStrokeInput.test(formData.mainPersonPosition),
            bankName: formData.bankName !== '' && regexStrokeInput.test(formData.bankName),
            accountNumber: formData.accountNumber !== '' && regexNumericInput.test(formData.accountNumber),
            routingNumber: formData.routingNumber !== '' && regexNumericInput.test(formData.routingNumber),
            emailCompany: formData.emailCompany !== '' && regexEmail.test(formData.emailCompany),
            phoneNumberCompany: formData.phoneNumberCompany !== '' && regexNumericInput.test(formData.phoneNumberCompany),
            postalAddressCompany: formData.postalAddressCompany !== '' && regexStrokeInput.test(formData.postalAddressCompany),
            legalAddressCompany: formData.legalAddressCompany !== '' && regexStrokeInput.test(formData.legalAddressCompany),
            BIC: formData.BIC !=='' && regexNumericInput.test(formData.BIC),
        };

        const isValidForm = Object.values(validations).every(validation => validation);
        setIsValidForm(isValidForm);

        setValidationText({
            nameCompany: validations.nameCompany ? '' : 'Пожалуйста, введите название компании',
            registrationNumber: validations.registrationNumber ? '' : 'Пожалуйста, введите корректный регистрационный номер',
            taxId: validations.taxId ? '' : 'Пожалуйста, введите корректный налоговый идентификационный номер',
            mainPersonName: validations.mainPersonName ? '' : 'Пожалуйста, введите имя главного лица',
            mainPersonPosition: validations.mainPersonPosition ? '' : 'Пожалуйста, введите должность главного лица',
            bankName: validations.bankName ? '' : 'Пожалуйста, введите название банка',
            accountNumber: validations.accountNumber ? '' : 'Пожалуйста, введите корректный номер счета',
            routingNumber: validations.routingNumber ? '' : 'Пожалуйста, введите корректный номер маршрутизации',
            emailCompany: validations.emailCompany ? '' : 'Пожалуйста, введите корректный email',
            phoneNumberCompany: validations.phoneNumberCompany ? '' : 'Пожалуйста, введите корректный номер телефона',
            postalAddressCompany: validations.postalAddressCompany ? '' : 'Пожалуйста, введите почтовый адрес компании',
            legalAddressCompany: validations.legalAddressCompany ? '' : 'Пожалуйста, введите юридический адрес компании',
            BIC: validations.BIC ? '' : 'Пожалуйста, введите БИК',
        });

    }, [formData])

    return (
        <div className="form-add-record">
            <p className="form-add-record__title">Добавление компании</p>
            <div className="form-add-record__container-input">
                <InputText
                    inputValue={formData.nameCompany}
                    setInputChange={(e) => handleChange(e, 'nameCompany')}
                    placeholder={'Название компании'}
                    label={'Название компании'}
                    validationText={validationText.nameCompany}
                />
                <InputText
                    inputValue={formData.taxId}
                    setInputChange={(e) => handleChange(e, 'taxId')}
                    placeholder={'ИНН'}
                    label={'ИНН'}
                    type={'number'}
                    validationText={validationText.taxId}
                />
                 <InputText
                    inputValue={formData.registrationNumber}
                    setInputChange={(e) => handleChange(e, 'registrationNumber')}
                    placeholder={'КПП'}
                    label={'КПП'}
                    type={'number'}
                    validationText={validationText.registrationNumber}
                />
                <InputText
                    inputValue={formData.mainPersonName}
                    setInputChange={(e) => handleChange(e, 'mainPersonName')}
                    placeholder={'Имя главного лица'}
                    label={'Имя главного лица'}
                    validationText={validationText.mainPersonName}
                />
                <InputText
                    inputValue={formData.mainPersonPosition}
                    setInputChange={(e) => handleChange(e, 'mainPersonPosition')}
                    placeholder={'Должность главного лица'}
                    label={'Должность главного лица'}
                    validationText={validationText.mainPersonPosition}
                />
                <InputText
                    inputValue={formData.bankName}
                    setInputChange={(e) => handleChange(e, 'bankName')}
                    placeholder={'Название банка'}
                    label={'Название банка'}
                    validationText={validationText.bankName}
                />
                <InputText
                    inputValue={formData.BIC}
                    setInputChange={(e) => handleChange(e, 'BIC')}
                    placeholder={'БИК'}
                    label={'БИК'}
                    type={'number'}
                    validationText={validationText.BIC}
                />
                <InputText
                    inputValue={formData.accountNumber}
                    setInputChange={(e) => handleChange(e, 'accountNumber')}
                    placeholder={'Лицевой счет'}
                    label={'ЛС'}
                    type={'number'}
                    validationText={validationText.accountNumber}
                />
                <InputText
                    inputValue={formData.routingNumber}
                    setInputChange={(e) => handleChange(e, 'routingNumber')}
                    placeholder={'Корреспондентский счет'}
                    label={'КС'}
                    type={'number'}
                    validationText={validationText.routingNumber}
                />
                <InputText
                    inputValue={formData.emailCompany}
                    setInputChange={(e) => handleChange(e, 'emailCompany')}
                    placeholder={'Email компании'}
                    label={'Email компании'}
                    validationText={validationText.emailCompany}
                />
                <InputText
                    inputValue={formData.phoneNumberCompany}
                    setInputChange={(e) => handleChange(e, 'phoneNumberCompany')}
                    placeholder={'Номер телефона компании'}
                    label={'Номер телефона компании'}
                    type={'number'}
                    validationText={validationText.phoneNumberCompany}
                />
                <InputText
                    inputValue={formData.postalAddressCompany}
                    setInputChange={(e) => handleChange(e, 'postalAddressCompany')}
                    placeholder={'Почтовый адрес компании'}
                    label={'Почтовый адрес компании'}
                    validationText={validationText.postalAddressCompany}
                />
                <InputText
                    inputValue={formData.legalAddressCompany}
                    setInputChange={(e) => handleChange(e, 'legalAddressCompany')}
                    placeholder={'Юридический адрес компании'}
                    label={'Юридический адрес компании'}
                    validationText={validationText.legalAddressCompany}
                />
            </div>
            <ButtonWithBackgroundColor
                text={'Добавить компанию'}
                isDisabled={!isValidForm}
                onClick={submit}
            />
            <p className="form-add-work__error-message">{messageValidForm}</p>
        </div>
    );
}

export default FormAddCompany;