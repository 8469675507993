import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from '../../context/AppContext';

import ButtonNavigationBack from "../../components/buttons/ButtonNavigationBack/ButtonNavigationBack";
import AddressBalance from "./components/AddressBalance";
import ButtonsControl from "./components/ButtonsControl";

import SearchForm from "../../components/SearchForm/SearchForm";
import Loader from "../../components/Loader/Loader";
import InfoTooltip from "../../components/modals/InfoTooltip/InfoTooltip";
import addressDataApi from "../../utils/api/addressDataApi";
import FormUpdateDate from "./components/forms/FormUpdateDate";
import Employees from "./components/Employees/Employees";

function ServicesPage() {

    const [address, setAddress] = useState(undefined);
    const [addressesEmployees, setServiceEmployees] = useState([]);
    const [searchingStroke, setSearchingStroke] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isOpenInfoTooltip, setIsOpenInfoTooltip] = useState(false);
    const [textInfoTooltip, setTextInfoTooltip] = useState('');
    //Компоненты
    const [isEmployees, setIsEmployees] = useState(false);
    // Формы
    const [isOpenedFormDateUpdate, setIsOpenedFormDateUpdate] = useState(false);
    //
    const [isServiceEmployees, setIsServiceEmployees] = useState(false);
    const [isService, setIsService] = useState(true);
    // Лоадеры
    const [isLoadinAddServiceEmployees, setIsLoadinAddServiceEmployees] = useState(false);

    const navigate = useNavigate();
    const { dictionary, idDevice } = useAppContext();

    function onClickBackPage(e) {
        e.preventDefault();
        navigate('/');
    }

    function closeInfoTool() {
        setIsOpenInfoTooltip(false);
    }

    async function search(searchKey) {
        try {
            setIsLoading(true);
            const findedAddress = await addressDataApi.getAddressDataByIdForPay(searchKey);
            setAddress(findedAddress.address);
            setIsLoading(false);
        }
        catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    }

    function openServiceEmployees() {
        setIsServiceEmployees(true);
        setIsService(false);
    }

    function openService() {
        setIsServiceEmployees(false);
        setIsService(true);
    }


    function openInfoTooltip(text) {
        setIsOpenInfoTooltip(true);
        setTextInfoTooltip(text);
    }

    // Форма обновления даты подписки
    function openFormDateUpdate() {
        setIsOpenedFormDateUpdate(true);
    }

    function closeFormDateUpdate() {
        setIsOpenedFormDateUpdate(false);
    }

    async function formUpdateDate(date) {
        try {
            const updatedAddress = await addressDataApi.updateAddressDataValidUntilById(address._id, date);
            setAddress(updatedAddress.address);
        } catch (err) {
            console.log(err)
        }
    }

    // Управление открытием компонентов
    function openComponent(name) {
        const array = [{ name: "employees", set: setIsEmployees }]
        array.forEach((item) => item.name === name ? item.set(true) : item.set(false))
    }

    return (
        <section className="addresses">
            <ButtonNavigationBack onClick={onClickBackPage} />
            <h2 className="addresses__title">Адрес</h2>
            <div className="addresses__search-form">
                <SearchForm
                    onSubmit={search}
                    text={searchingStroke}
                    setText={setSearchingStroke}
                    placeholder={'Введите уникальный идентификатор'}
                />
            </div>
            {isLoading ?
                <Loader customStyle={'loader__gray__w25h25'} />
                :
                address ?
                    <div className="addresses__containeiner">
                        <div className="addresses__container-balance-info">
                            <p>{address?.companyInfo?.name} {address?.address?.city} {address?.address?.street} {address?.address?.buiding}</p>
                            <AddressBalance
                                address={address}
                                handleOpenUpdateDate={openFormDateUpdate}
                            />
                        </div>
                        <ButtonsControl
                            handleOpenEmployees={() => { openComponent('employees') }}
                        />
                    </div>
                    : <></>
            }
            {isEmployees && address ?
                <Employees addressId={address._id} />
                : <></>}
            <InfoTooltip isOpen={isOpenInfoTooltip} text={textInfoTooltip} closeInfoTool={closeInfoTool} />
            <FormUpdateDate
                isOpen={isOpenedFormDateUpdate}
                close={closeFormDateUpdate}
                handleSubmit={formUpdateDate}
                isLoading={false}
            />
        </section>
    )
}

export default ServicesPage;