import ButtonWithBorder from "../../../components/buttons/ButtonWithBorder/ButtonWithBorder";

/** Контейнер с кнопками управления покаом настроек адреса */
function ButtonsControl({handleOpenEmployees}) {
    
    return (
    <div className="addresses__container-buttons">
        <ButtonWithBorder text={'Сотрудники'} onClick={handleOpenEmployees} />
        <ButtonWithBorder text={'Реквизиты'} onClick={()=>{}} />
        <ButtonWithBorder text={'КУ'} onClick={()=>{}} />
        <ButtonWithBorder text={'Второстепенные'} onClick={()=>{}} />
        <ButtonWithBorder text={'Общедомовые'} onClick={()=>{}} />
        <ButtonWithBorder text={'Резиденты'} onClick={()=>{}} />
    </div>)
}

export default ButtonsControl;