
import { useEffect, useState } from 'react';
import { useAppContext } from '../../context/AppContext';
import { regexStrokeInput } from '../../utils/regex';
import submit from './../../images/find.svg';

function SearchForm({ onSubmit, children, placeholder, text, setText }) {

    const [isValidForm, setIsValidForm] = useState(false);
    const [validationMassegeName, setValidationMassegeName] = useState('');

    const { dictionary } = useAppContext();

    //передать данные  из инпутов
    function handleChange(e) {
        setText(e.target.value);
        setValidationMassegeName(e.target.validationMessage);
    }

    function handleSubmit(e) {
        if (isValidForm) {
            e.preventDefault();
            onSubmit(text);
        }
    }

    // Валидация
    useEffect(() => {
        if (text !== '') {
            if (regexStrokeInput.test(text)) {
                setIsValidForm(true);
                setValidationMassegeName('');
            } else {
                setIsValidForm(false);
                setValidationMassegeName(dictionary.validationRegex);
            }
        } else {
            setIsValidForm(true);
        }
    }, [text])


    return (
        <form className="search-form" onSubmit={handleSubmit}>
            <div className="search-form__container">
                <div className="search-form__container-input">
                    <div className="search-form__icon" alt="картинка поиска" />
                    <input className="search-form__input" type="text" value={text} name="name" placeholder={placeholder ? placeholder : dictionary.placeholderSearchForm} onChange={handleChange}></input>
                    <button className="search-form__input-button">
                        <img className="search-form__img" src={submit} alt="изибражение стрелки" />
                    </button>
                </div>
                {children ?
                    <div className="search-form__checkbox">
                        {children}
                        {/* <button className={`search-form__button ${isCheckMovies ? 'search-form__button_on' : 'search-form__button_off'}`}
                            onClick={changeFilms} />
                        <p className="search-form__button-name">{placeholder}</p> */}
                    </div>
                    : <></>}
            </div>
            <span className="input__text-error" id="error-firstname">{validationMassegeName}</span>
        </form>
    )
}

export default SearchForm;