import InfoTooltip from "../../modals/InfoTooltip/InfoTooltip";
import ButtonWithBorder from "../../buttons/ButtonWithBorder/ButtonWithBorder";

function FormRemove({ isOpen, closeForm, onRemove, isLoading }) {

    return (
        <InfoTooltip
            isOpen={isOpen}
            closeInfoTool={closeForm}
            text={'Удалить'}
            children={
                <div className="form-remove__container-buttons">
                    <ButtonWithBorder text={'Да'} onClick={onRemove} isLoading={isLoading} />
                    <ButtonWithBorder text={'Нет'} onClick={closeForm} />
                </div>
            }
        />
    )
}

export default FormRemove;