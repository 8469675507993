import React, { useEffect, useState } from "react";
import InputDateTimePicker from "../../../../../components/InputDateTimePicker/InputDateTimePicker";
import ButtonWithBackgroundColor from "../../../../../components/buttons/ButtonWithBackgroundColor/ButtonWithBackgroundColor";

/**
 * Компонент формы для выбора даты действия подписки.
 * @param {Object} props - Свойства компонента.
 * @param {Object} props.handleSubmit - Функция обработки сабмита формы.
 * @param {Function} props.handleSubmit(formData) - Функция, которая будет вызвана при сабмите формы. Принимает объект formData с данными формы.
 * @returns {JSX.Element} - Элемент компонента формы добавления адреса.
 */
function FormChoiseDate({ handleSubmit, isLoading }) {
    const [date, setDate] = useState(new Date());

    function submit() {
        handleSubmit({ validUntil: date })
    }

    return (
        <div className="form-add-record">
            <p className="form-add-record__title">Дата действия подписки</p>
            <div className="form-add-record__container-input">
                <InputDateTimePicker
                    handleSetDateTime={setDate}
                    validationText={'Выберите дату'}
                />
                <ButtonWithBackgroundColor
                    text={'Готово'}
                    onClick={submit}
                    isLoading={isLoading}
                />
            </div>
        </div>
    );
}

export default FormChoiseDate;