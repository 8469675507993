import { useEffect, useState } from "react";

import InfoTooltip from "../../../../../components/modals/InfoTooltip/InfoTooltip";
import InputText from "../../../../../components/InputText/InputText";
import InputSelect from "../../../../../components/InputSelect/InputSelect";
import ButtonWithBorder from "../../../../../components/buttons/ButtonWithBorder/ButtonWithBorder";

import { regexEmail, regexNumericInput, regexStrokeInput } from "../../../../../utils/regex";
import employeeApi from "../../../../../utils/api/employeeApi";

function FormUpdateEmployee({ isOpen, closeForm, isLoading, onSubmit, currentEmployee, textValidation }) {

    const [name, setName] = useState('');
    const [nameValidText, setNameValidText] = useState('');
    const [email, setEmail] = useState('');
    const [emailValidText, setEmailValidText] = useState('');
    const [role, setRole] = useState('');
    const [roleValidText, setRoleValidText] = useState('');
    const [position, setPosition] = useState('');
    const [positionValidText, setPositionValidText] = useState('');
    const [phone, setPhone] = useState('');
    const [phoneValidText, setPhoneValidText] = useState('');
    const [wa, setWa] = useState('');
    const [waValidText, setWaValidText] = useState('');
    const [isValid, setIsValid] = useState(false);

    const optionRole = [
        {
            value: 'manager', name: 'председатель/управляющий'
        },
        {
            value: 'account', name: 'бухгалтер'
        },
    ]

    function submit() {

        const data = {
            id: currentEmployee._id,
            name,
            email,
            position,
            role: role.value,
        }

        onSubmit(data, currentEmployee)
    }

    // наполнить инпуты
    useEffect(() => {
        if (currentEmployee) {
            setName(currentEmployee.name);
            const currentRole = optionRole.find((item) => item.value === currentEmployee.role);
            setRole(currentRole);
        }
       
        if (currentEmployee?.contactData?.email) {
            setEmail(currentEmployee.contactData.email)
        }
        if (currentEmployee?.contactData?.phoneNumber) {
            setPhone(currentEmployee.contactData.phoneNumber)
        }
        if (currentEmployee?.contactData?.messengerNumber) {
            setWa(currentEmployee.contactData.messengerNumber)
        }
    }, [currentEmployee])

    // Валидация
    useEffect(() => {

        const optionsValid = [
            { state: name, setValidText: setNameValidText, regex: regexStrokeInput, isValid: false },
            { state: email, setValidText: setEmailValidText, regex: regexEmail, isValid: false },
            { state: role.value, setValidText: setRoleValidText, regex: regexStrokeInput, isValid: false },
            { state: phone, setValidText: setPhoneValidText, regex: regexNumericInput, isValid: false },
            { state: wa, setValidText: setWaValidText, regex: regexNumericInput, isValid: false },
            { state: position, setValidText: setPositionValidText, regex: regexStrokeInput, isValid: false },
        ]

        optionsValid.forEach(option => {
            if (option.state !== '') {
                if (option.regex.test(option.state)) {
                    option.isValid = true;
                    option.setValidText('')
                } else {
                    option.isValid = false;
                    option.setValidText('Не корректный ввод')
                }
            } else {
                option.isValid = false;
                option.setValidText('Пропущено поле')
            }
        })

        const valid = optionsValid.find(option => option.isValid === false);
        setIsValid(!valid);

    }, [name, email, role])

    return (
        <InfoTooltip
            isOpen={isOpen}
            closeInfoTool={closeForm}
            text={'Редатировать сотрудника'}
            children={
                <div className="employees__form-add-employee">
                    <>
                        <InputText inputValue={email} setInputChange={setEmail} placeholder={'Email'} validationText={emailValidText} label={'Email'} type={'email'} />
                        <InputText inputValue={name} setInputChange={setName} placeholder={'ФИО'} validationText={nameValidText} label={'ФИО'} />
                        <InputText inputValue={position} setInputChange={setPosition} placeholder={'Должность'} validationText={positionValidText} label={'Должность'} />
                        <InputText inputValue={phone} setInputChange={setPhone} placeholder={'Телефон'} validationText={phoneValidText} label={'Тел'} type={'number'} />
                        <InputText inputValue={wa} setInputChange={setWa} placeholder={'WhatsApp'} validationText={waValidText} label={'WA'} type={'number'} />
                        <InputSelect options={optionRole} setSelectValue={setRole} value={role} placeholder={'Роль'} validationText={roleValidText} handleInputChange={() => { }} />
                        <div className="employees__form-add-employee-button">
                            <ButtonWithBorder text={'Сохранить изменения'} onClick={submit} isLoading={isLoading} isDisabled={false} />
                        </div>
                        <p>{textValidation}</p>
                    </>
                </div>
            }
            customStyle={''}
        />
    )
}

export default FormUpdateEmployee;