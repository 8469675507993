import { Routes, Route } from 'react-router-dom';
import { useAppContext } from './context/AppContext';
import Header from './Pages/MainPage/Components/Header/Header';
import Footer from './components/Footer/Footer';
import MainPage from './Pages/MainPage/MainPage';
import LoginPage from './Pages/LoginPage/LoginPage';
import NewServicePage from './Pages/NewServicePage/NewServicePage';
import NotFoundPage from './components/NotFoundPage/NotFoundPage';
import AddressesPage from '../src/Pages/AddressesPage/AddressesPage';
import LoaderPage from './components/LoaderPage/LoaderPage';

function App() {

  const { isAuthenticated, isLoadingAuthenticated } = useAppContext();

  return (
    <div className="app">
      <main className="app-page">
        <Header />
        {isLoadingAuthenticated ?
          <LoaderPage />
          :
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/" element={isAuthenticated ? <MainPage /> : <LoginPage />} />
            <Route path="/add-service" element={isAuthenticated ? <NewServicePage /> : <NotFoundPage />} />
            <Route path="/addresses" element={isAuthenticated ? <AddressesPage /> : <NotFoundPage />} />
            <Route path="/*" element={<NotFoundPage />} />
          </Routes>}
        <Footer />
      </main>
    </div>
  );
}

export default App;
