import React, { useEffect, useState } from "react";
import InputText from "../../../../../components/InputText/InputText";
import ButtonWithBackgroundColor from "../../../../../components/buttons/ButtonWithBackgroundColor/ButtonWithBackgroundColor";
import { regexEmail, regexNumericInput, regexStrokeInput } from "../../../../../utils/regex";
import List from "../../../../../components/list/List/List";

/**
 * Компонент формы для добавления контактов компании.
 * @param {Object} props - Свойства компонента.
 * @param {Object} props.handleSubmit - Функция обработки сабмита формы.
 * @returns {JSX.Element} - Элемент компонента формы добавления контактов компании.
 */
function FormAddContactsCompany({ handleSubmit }) {
    const [formData, setFormData] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [contactData, setContactData] = useState({
        name: "",
        description: "",
        email: "",
        phoneNumber: "",
        messengerNumber: "",
    });

    const [isValidContactForm, setIsValidContactForm] = useState(false);
    const [isValidForm, setIsValidForm] = useState(false);

    const handleChange = (value, fieldName) => {
        setContactData((prevState) => ({
            ...prevState,
            [fieldName]: value,
        }));
    };

    function addContact() {
        setFormData((prevData) => [...prevData, contactData]);
        // Очистка формы после добавления контакта
        setContactData({
            name: "",
            description: "",
            email: "",
            phoneNumber: "",
            messengerNumber: "",
        });
        // Добавление контакта в список для отображения
        const contact = {
            name: contactData.name,
            text: `${contactData.description} email: ${contactData.email} phone: ${contactData.phoneNumber} message ${contactData.messengerNumber}`
        }
        setContacts((prevData) => [...prevData, contact]);
    }

    function removeItem(contact) {
        const newArray = formData.filter(item => item.name !== contact.name);
        setFormData(newArray);
        const newList = contacts.filter(item => item.name !== contact.name);
        setContacts(newList);
    }

    function submit() {
        const data = { contacts: formData }
        handleSubmit(data);
        // Очистка массива контактов после сабмита формы
        setFormData([]);
        setContacts([]);
    }

    useEffect(() => {
        const validations = {
            name: contactData.name !== '' && regexStrokeInput.test(contactData.name),
            description: contactData.description !== '' && regexStrokeInput.test(contactData.description),
            phoneNumber: contactData.phoneNumber !== '' && regexNumericInput.test(contactData.phoneNumber),
            email: contactData.email !== '' && regexEmail.test(contactData.email)
        };

        const isValidForm = Object.values(validations).every(validation => validation);
        setIsValidContactForm(isValidForm);

    }, [contactData]);

    useEffect(() => {
        const isValid = contacts.length > 0;
        setIsValidForm(isValid);
    }, [contacts]);

    return (
        <div className="form-add-record">
            <p className="form-add-record__title">Добавление контактов компании</p>
            <div className="form-add-record__container-input">
                <InputText
                    inputValue={contactData.name}
                    setInputChange={(e) => handleChange(e, "name")}
                    placeholder={"Имя контакта"}
                    label={"Имя контакта"}
                    validationText={!contactData.name ? "Введите имя контакта" : ""}
                />
                <InputText
                    inputValue={contactData.description}
                    setInputChange={(e) => handleChange(e, "description")}
                    placeholder={"Описание контакта"}
                    label={"Описание контакта"}
                    validationText={contactData.description !== '' && regexStrokeInput.test(contactData.description) ? "" : 'Введите описание'}
                />
                <InputText
                    inputValue={contactData.email}
                    setInputChange={(e) => handleChange(e, "email")}
                    placeholder={"Email"}
                    label={"Email"}
                    validationText={contactData.email !== '' && regexEmail.test(formData.email) ? "Введите email" : ""}
                />
                <InputText
                    inputValue={contactData.phoneNumber}
                    setInputChange={(e) => handleChange(e, "phoneNumber")}
                    placeholder={"Номер телефона"}
                    label={"Номер телефона"}
                    type={"number"}
                    validationText={!contactData.phoneNumber ? "Введите номер телефона" : ""}
                />
                <InputText
                    inputValue={contactData.messengerNumber}
                    setInputChange={(e) => handleChange(e, "messengerNumber")}
                    placeholder={"Номер мессенджера"}
                    label={"Номер мессенджера"}
                    type={"number"}
                />
            </div>
            <div className="form-add-record__button-container">
                <ButtonWithBackgroundColor
                    text={"Добавить контакт"}
                    onClick={addContact}
                    isDisabled={!isValidContactForm}
                />
            </div>
            <div className="form-add-record__list">
                <List array={contacts} onClickRemoveItem={removeItem}/>
            </div>
            <div className="form-add-record__button-container">
                <ButtonWithBackgroundColor
                    text={"Сохранить контакты"}
                    onClick={submit}
                    isDisabled={!isValidForm}
                />
            </div>
        </div>
    );
}

export default FormAddContactsCompany;
